import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import styled from 'styled-components';

import { Colors } from '../../styles/colors';
import { media } from '../../styles/media';

const coverLetterGeneratorImage1 = '/images/tools/cover-letter-1.png';

import { coverLetterStateMozard } from '../../states/coverletterMozard';
import { coverLetterBeethoven } from '../../states/coverletterBeethoven';
import { coverLetterChopin } from '../../states/coverletterChopin';

import { CoverLetterPreview } from '../../components/molecules/CoverLetterPreview';
import { Copy, H2, H3 } from '../../components/atoms/Typography';
import { TwoColumnsIntro } from '../../components/molecules/TwoColumnsIntro';
import { PrimaryButtonLink } from '../../components/atoms/Button/Buttons';
import { Spacer } from '../../components/atoms/Spacer/Spacer';
import { ChevronRightIcon } from '../../components/atoms/Icons';
import { Layout } from '../../components/atoms/Layout/Layout';
import { BackgroundColor } from '../../components/atoms/BackgroundColor';
import { Pricing } from '../../components/molecules/Pricing';
import {
  ResumesPreviewCentralWrapper,
  ResumesPreviewLeftWrapper,
  ResumesPreviewRightWrapper,
  ResumesPreviewWrapper,
} from '../../components/molecules/ResumePreview';
import { FunctionalIFrameComponent } from '../../components/atoms/FunctionalIFrameComponent';

const CoverLetterGenerator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Helmet title="Cover Letter Generator" />

      <BackgroundColor backgroundColor={Colors.ContrastLightest} hasWave={false}>
        <TableWrapper>
          <Wrapper>
            <ResumesPreviewWrapper>
              <ResumesPreviewLeftWrapper>
                <FunctionalIFrameComponent title="resume">
                  <CoverLetterPreview state={coverLetterBeethoven} />
                </FunctionalIFrameComponent>
              </ResumesPreviewLeftWrapper>
              <ResumesPreviewCentralWrapper>
                <FunctionalIFrameComponent title="resume">
                  <CoverLetterPreview state={coverLetterStateMozard} />
                </FunctionalIFrameComponent>
              </ResumesPreviewCentralWrapper>
              <ResumesPreviewRightWrapper>
                <FunctionalIFrameComponent title="resume">
                  <CoverLetterPreview state={coverLetterChopin} />
                </FunctionalIFrameComponent>
              </ResumesPreviewRightWrapper>
            </ResumesPreviewWrapper>
          </Wrapper>
          <Wrapper>
            <H2>AI-Powered Cover Letter</H2>
            <H3>Craft Your Perfect Cover Letter with Ease</H3>
            <Copy>
              Impress employers with a tailored cover letter for every application, igniting your journey towards
              endless career opportunities.
            </Copy>
            <Spacer y={32} />
            <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
              Create your Cover Letter
            </PrimaryButtonLink>
          </Wrapper>
        </TableWrapper>
      </BackgroundColor>

      <TwoColumnsIntro image={coverLetterGeneratorImage1} isInverted={false}>
        <H3>Tailored Cover Letters for Impactful Applications</H3>
        <H2>Cover Letter Generator</H2>
        <Copy>
          Supercharge your job search with AI-crafted cover letters. Our generator kick-starts your application journey
          by tailoring content to your profile and the specific job opportunity.
        </Copy>
        <Spacer y={32} />
        <PrimaryButtonLink inline={true} to="/login" iconRight={<ChevronRightIcon color={Colors.White} />}>
          See the AI Features in Action
        </PrimaryButtonLink>
      </TwoColumnsIntro>

      <BackgroundColor backgroundColor={Colors.White} hasWave={false}>
        <Layout>
          <Pricing />
        </Layout>
      </BackgroundColor>
    </>
  );
};

export default CoverLetterGenerator;

const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  ${media.from_tablet`
    grid-template-columns: 2fr 1fr;
    gap: 64px;
    > :nth-of-type(1) {
      order: 1;
    }
    > :nth-of-type(2) {
      order: 2;
    }
    > :nth-of-type(3) {
      order: 3;
    }
    > :nth-of-type(4) {
      order: 4;
    }
    > :nth-of-type(5) {
      order: 6;
    }
    > :nth-of-type(6) {
      order: 5;
    }
    > :nth-of-type(7) {
      order: 7;
    }
    > :nth-of-type(8) {
      order: 8;
    }
  `}
`;

const Wrapper = styled.div``;
